import React, { useState, useContext, useEffect, useCallback } from "react"
import { useCookies } from "react-cookie"
import axios from "axios"

import "./style.scss"
import CheckoutBlock from "../CheckoutBlock"
import NewShippingAddress from "../NewShippingAddress"
import CheckoutRadio from "../CheckoutRadio"
import CheckoutContext from "../../context/checkoutContext"
import CustomerContext from "../../context/customerContext"
import PrescriptionRadio from "../PrescriptionRadio"
import useNewRxFlow from "../../utils/useNewRxFlow"
import useNewRxFlowDesktop from "../../utils/useNewRxFlowDesktop";
import RequiredMessage from "../RequiredMessage";

const CheckoutShippingAddress = ({payPalAddress, emojiErrors, setEmojiErrors}) => {
  const {stripeCustomer, setStripeCustomer} = useContext(CustomerContext);
  const customerData = JSON.parse(localStorage.getItem("customer_data"))
  const [cookies] = useCookies(["user-jwt"])
  const user_token = cookies["user-jwt"]
  const [smsSubscribe, setSmsSubscribe] = useState('')
  const [isNewAddress, setIsNewAddress] = useState(false)
  const isNewRx = useNewRxFlow()
  const isNewRxDesktop = useNewRxFlowDesktop()
  const {
    customer,
    setCustomer,
    isValid,
    shippingAddress,
    setShippingAddress,
    setIsValid,
  } = useContext(CheckoutContext)

  const handleSignUpNewsletter = () => {
    setSmsSubscribe(smsSubscribe === "sms" ? "" : "sms")
    setCustomer({...customer, sms_subscribe: smsSubscribe === 'sms' })
  }

  const getCustomerData = useCallback(async () => {
    if (stripeCustomer?.type === "stripe") return

    axios.get(`${process.env.GATSBY_API_URL}/api/customer_data`, {
      params: {
        user_token: user_token,
      },
    }).then((response) => {
      setStripeCustomer({...response.data.data, type: "recharge"})
      localStorage.setItem('customer_data', JSON.stringify({...response.data.data, type: "recharge"}))
    })
      .catch((error) => { })
  }, [])

  useEffect(() => {
    if (user_token && stripeCustomer?.type !== "stripe") {
      getCustomerData()
    }
  }, [cookies])

  return (
    <CheckoutBlock id="shipping">
      <CheckoutBlock.Header title="Shipping Address" />
      <CheckoutBlock.Body>
        {user_token ? (
          <SignedShipping payPalAddress={payPalAddress} customer={Object.keys(stripeCustomer).length ? stripeCustomer : customerData || {}} setIsNewAddress={setIsNewAddress} />
        ) : (
          <NewShippingAddress
            payPalAddress={payPalAddress}
            isValid={isValid.shippingAddress}
            setIsValid={value =>
              setIsValid({ ...isValid, shippingAddress: value })
            }
            setAddress={obj =>
              setShippingAddress({ ...shippingAddress, ...obj })
            }
            emojiErrors={emojiErrors}
            setEmojiErrors={setEmojiErrors}
          />
        )}
        {(isNewRx || isNewRxDesktop) && (!user_token || (user_token && isNewAddress)) && <PrescriptionRadio
        value="sms"
        label="Check this box to receive special VIP rewards and promotions via text from Hubble at the phone number below. I understand that my consent is not a condition of purchase."
        onClick={() => handleSignUpNewsletter()}
        active={smsSubscribe}
      /> }
      </CheckoutBlock.Body>
    </CheckoutBlock>
  )
}

const SignedShipping = ({payPalAddress, customer, setIsNewAddress }) => {
  const { isRequiredMessage } = useContext(CheckoutContext)
  const [active, setActive] = useState("")
  const {
    isValid,
    shippingAddress,
    setShippingAddress,
    setIsValid,
    isAddressShouldReset,
    setIsAddressShouldReset,
  } = useContext(CheckoutContext)

  useEffect(() => {
    if (isAddressShouldReset) {
      setIsNewAddress(false)
      setActive("")
      setIsAddressShouldReset(false)
      setIsValid({ ...isValid, shippingAddress: false })
    }
  }, [isAddressShouldReset]);

  const handleRadio = item => {
    if (item === "new") {
      setIsNewAddress(true)
      setActive(item)
      return setIsValid({ ...isValid, shippingAddress: false })
    }

    const {
      id,
      first_name,
      last_name,
      billing_company,
      billing_address1,
      billing_address2,
      billing_city,
      billing_province,
      billing_zip,
      phone,
    } = item

    setActive(id)
    setShippingAddress({
      ...shippingAddress,
      first_name,
      last_name,
      company: billing_company || "",
      address: billing_address1 || customer?.shipping?.address?.line1,
      address_opt: billing_address2 || customer?.shipping?.address?.line2,
      city: billing_city || customer?.shipping?.address?.city,
      state: billing_province || customer?.shipping?.address?.state,
      zip: billing_zip || customer?.shipping?.address?.postal_code,
      phone,
    })
    setIsValid({ ...isValid, shippingAddress: true })
    setIsNewAddress(false)
  }

  const getCustomerAddress = (customer) => {
    const address1 = customer.billing_address1 || customer.shipping.address.line1
    const address2 = customer.billing_address2 || customer.shipping?.address.line2 || ""
    const city = customer.billing_city || customer.shipping.address.city
    const state = customer.billing_province || customer.shipping.address.state
    const zip = customer.billing_zip || customer.shipping.address.postal_code

    return `${address1}, ${address2}${address2 && ","} ${city} ${state}, ${zip}`
  }

  return (
    <div className="signed-shipping">
      <p className="title text h8 bulky">Saved Addresses</p>
      <div className={`saved-addresses ${shippingAddress && !shippingAddress.address && isRequiredMessage ? "error" : ""}`}>
        {Object.keys(customer).length !== 0 && <CheckoutRadio
          value={customer?.id}
          active={active}
          onClick={() => handleRadio(customer)}
          key={customer?.id}
        >
          <CheckoutRadio.Header>
            <span className="address text h8 neutral">{getCustomerAddress(customer)}</span>
          </CheckoutRadio.Header>
        </CheckoutRadio>}
      </div>
      <CheckoutRadio
        value="new"
        active={active}
        onClick={() => handleRadio("new")}
      >
        <CheckoutRadio.Header label="Use a different shipping address" />
      </CheckoutRadio>
      {active === "new" && (
        <NewShippingAddress
          payPalAddress={payPalAddress}
          isValid={isValid.shippingAddress}
          setIsValid={value =>
            setIsValid({ ...isValid, shippingAddress: value })
          }
          setAddress={obj => setShippingAddress({ ...shippingAddress, ...obj })}
        />
      )}
      {/* {shippingAddress && !shippingAddress.address && isRequiredMessage && <RequiredMessage />} */}
    </div>
  )
}

export default CheckoutShippingAddress
