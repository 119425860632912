import React, { useContext, useEffect, useState } from "react"
import CartContext from "../../context/cartContext"
import CustomerContext from "../../context/customerContext"
import useNewRxFlow from "../../utils/useNewRxFlow"
import useAbTest from '../../utils/useAbTest'

import "./style.scss"
import useIsLifemart from '../Lifemart/hooks/useIsLifemart'
import useNewRxFlowDesktop from "../../utils/useNewRxFlowDesktop";

const CheckoutTotal = ({ items }) => {
  const { discountPercentage , taxes, discountCode, subtotal: contextSubtotal } = useContext(CartContext)
  const { shippingPrice } = useContext(CustomerContext)
  const [isSunglassesDiscount, setIsSunglassesDiscount] = useState(JSON.parse(localStorage.getItem("discount_code"))?.isSunglassesDiscount)
  const [isFramesDiscount, setIsFramesDiscount] = useState(JSON.parse(localStorage.getItem("frames_discount")))
  const [generalDiscount, setGeneralDiscount] = useState(JSON.parse(localStorage.getItem("general_discount")))
  const [framesAndAccessoriesDiscount, setFramesAndAccessoriesDiscount] = useState(JSON.parse(localStorage.getItem("frames_accessories_discount")))
  const [contactsDiscount, setContactsDiscount] = useState(JSON.parse(localStorage.getItem("contact_lens_discount")))
  const isNewRx = useNewRxFlow()
  const isNewRxDesktop = useNewRxFlowDesktop()
  const isLifemart = useIsLifemart()
  const isPaidShipping = useAbTest()
  const hasContactsAvg = items?.some(e => e.type === "contacts" && e.isAvg)

  useEffect(() => {
    setIsSunglassesDiscount(JSON.parse(localStorage.getItem("discount_code"))?.isSunglassesDiscount)
    setIsFramesDiscount(JSON.parse(localStorage.getItem("frames_discount")))
    setGeneralDiscount(JSON.parse(localStorage.getItem("general_discount")))
    setContactsDiscount(JSON.parse(localStorage.getItem("contact_lens_discount")))
    setFramesAndAccessoriesDiscount(JSON.parse(localStorage.getItem("frames_accessories_discount")))
  }, [items, discountPercentage])

  const subtotal = parseFloat(items?.reduce((result, { price, sale_price, quantity, isOneEye, type }) => (result += (sale_price || price) * quantity * (type === "contacts" && !isOneEye && Number(sale_price || price) !== 1 ? 2 : 1)), 0)).toFixed(2)
  const discountedSubtotal = items?.reduce((subtotal, { price, quantity, sale_price, type, isOneEye, glassesBasePrice, sunglassesBasePrice, lensType, material, prescriptionType, nonSubscription }) => {
    let discountedPrice = (sale_price || price)
    const framesBasePrice = type === "glasses" ? glassesBasePrice : type === "sunglasses" ? sunglassesBasePrice : 0

    if (isSunglassesDiscount && type === "sunglasses") {
      discountedPrice = price - price * discountPercentage
    } else if (isFramesDiscount && (type === "glasses" || type === "sunglasses")) {
      if (discountCode.toLowerCase() === 'gift40') {
        discountedPrice = price - framesBasePrice * discountPercentage
      } else {
        discountedPrice = price - price * discountPercentage
      }
    } else if (contactsDiscount && type === "contacts" && !nonSubscription && items.length === 1) {
      discountedPrice = discountedPrice - discountedPrice * discountPercentage
    } else if (generalDiscount) {
      discountedPrice = discountedPrice - discountedPrice * discountPercentage
    } else if ((type === "contacts" && price === 1) || (type === "contacts" && framesAndAccessoriesDiscount)) {
      discountedPrice = price
    } else if (isLifemart && (type === "glasses" || type === "sunglasses")) {
      const addOnsPrice = lensType?.price + material?.price + prescriptionType?.price

      discountedPrice = (framesBasePrice + addOnsPrice) * 0.75
    }

    subtotal += discountedPrice * quantity * (type === "contacts" && !isOneEye && Number(sale_price || price) !== 1 ? 2 : 1)
    return subtotal
  }, 0)

  const isAnyLenses = !!items.find((item) => item?.type == "contacts")
  const isClassic = !!items.find((item) => item?.contacts_type == "classic")
  const isHydro = !!items.find((item) => item?.isHydro)
  const isSkyhy = !!items.find((item) => item?.isSkyhy)
  const isSkyhyMonthly = !!items.find((item) => item?.value === "skyhy-monthly")
  const isNonSubscription = items.some((item) => item?.nonSubscription)
  const isAvg = items.some((item) => item?.type === "avg" || item?.isAvg)

  const shipping = (((isHydro || isSkyhy) && discountedSubtotal != 0) || (isNonSubscription && discountedSubtotal != 0) || isAvg || (isClassic && isPaidShipping && discountedSubtotal != 0)) || isSkyhyMonthly ? shippingPrice : 0
  const discounted_price = parseFloat(discountedSubtotal) + parseFloat(shipping) + parseFloat(taxes)
  const total_price = parseFloat(contextSubtotal) + parseFloat(shipping) + parseFloat(taxes)

  const shippingDiscount = isAnyLenses && discountPercentage && (!contactsDiscount || contactsDiscount && items.length === 1) ? (shipping * discountPercentage).toFixed(2) : 0

  const total = (!discountPercentage && !isLifemart) ? total_price.toFixed(2) : (discounted_price-shippingDiscount).toFixed(2)

  return (
    <div className="checkout-total">
      <div className="price-list">
        <div className="price-element">
          <span className="text h8 neutral granite">Subtotal</span>
          <span className="text h8 neutral granite">${isLifemart ? parseFloat(discountedSubtotal).toFixed(2) : contextSubtotal}</span>
        </div>
        {!!discountPercentage &&
        <div className="price-element">
          <span className="text h8 neutral granite">Discount</span>
          <span className="text h8 neutral granite">${parseFloat(subtotal - discountedSubtotal + parseFloat(shippingDiscount)).toFixed(2)}</span>
        </div>}
        <div className="price-element">
          <span className="text h8 neutral granite">Shipping</span>
          <span className="text h8 neutral granite">
            {shipping ? `$${shipping}` : "Free"}
          </span>
        </div>
        <div className="price-element">
          <span className="text h8 neutral granite">Taxes</span>
          <span className="text h8 neutral granite">${taxes}</span>
        </div>
      </div>
      <div className={`total-container ${isNewRx ? "new-rx" : ""}`}>
        <span className={`text ${isNewRxDesktop ? "h8 bulky" : "h7 neue deep-blue"}`}>Total</span>
        <span className={`text ${isNewRxDesktop ? "h8 bulky" : "h7 neue deep-blue"}`}>${total}</span>
      </div>
    </div>
  )
}

export default CheckoutTotal
